import UpdateBlock from 'updater-html';




export default function updateBlockHome(versionElementById, functionName, updateElementById, settings) {

    let version = '';
    if (versionElementById) {
        console.log(versionElementById);
        version = document.getElementById(versionElementById).value;
    }



    let CSRF = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    let url = location.protocol + "//" + location.hostname + '/' + functionName + (version!=='' ? '/' + version : '')
    console.log(url);


    fetch(url,
        {
            method: 'POST',
            headers: {
                'X-CSRF-TOKEN': CSRF
            }
        }
    )
        .then((data) => {
            data.json().then((data) => {
                let oldDom = document.getElementById(updateElementById);
                let docNew = new DOMParser().parseFromString(data.data, "text/html");
                let start = new Date().getTime(); //Needs for count time

                let settingsUpdateBlockDefault = {
                    newDomDocument: docNew,
                    timeCloseBlink: 500,
                    oldDomDocument: oldDom,
                    considerSpaces: false,
                    debug: false
                };
                let settingsUpdateBlock = Object.assign({}, settingsUpdateBlockDefault, settings);
                UpdateBlock(settingsUpdateBlock);
                let end = new Date().getTime(); //Needs for count time
                console.log('Execution time of the script (millisecond)' + String(Number(end) - Number(start)));
            });

        });
}

