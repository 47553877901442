import updateBlockHome from '../main'


document.getElementById('home-managers').addEventListener('click', event => {
    updateBlockHome(null, 'getHomeManagersBlock', 'home-managers', {onlyAddAndDelete: true});
});
document.getElementById('home-list').addEventListener('click', event => {
    updateBlockHome('homeListVersion', 'getHomeListBlock', 'home-list');
});
document.getElementById('home-statistic').addEventListener('click', event => {
    updateBlockHome('homeStatisticVersion', 'getHomeStatisticBlock', 'home-statistic', {onlyUpdate: true});
});

let isWorkingTable = false;

function onEntry(entry) {
    if (!isWorkingTable) {
        entry.forEach(change => {
            if (change.isIntersecting) {
                updateBlockHome('homeTableVersion', 'getHomeTableBlock', 'home-table', {onlyUpdate: true}),
                    setInterval(() =>
                            updateBlockHome('homeTableVersion', 'getHomeTableBlock', 'home-table', {onlyUpdate: true}),
                        2000);
                isWorkingTable = true;
            }
        });
    }
}

let observer = new IntersectionObserver(onEntry, {threshold: [0.5]});
observer.observe(document.querySelector('#home-table'));
